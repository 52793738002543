.site-footer {
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 13px;
  background-color: #fafafa;
  .footer-container {
    padding: 24px 70px;
  }
  .brand-image {
    margin-bottom: 15px;
    display: inline-block;
  }
  .text {
    font-size: 12px;
    color: #171a1d;
    max-width: 147px;
    width: 100%;
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
  .nav-link {
    padding: 0px 0px 10px 0px;
    img {
      max-width: 128px;
    }
  }
  .col-12:nth-child(4) {
    padding-right: 0px;
    @media (max-width: 992px) {
      padding-right: 15px;
    }
  }
  .col-12:nth-child(5) {
    padding-left: 30px;
    @media (max-width: 992px) {
      padding-left: 15px;
    }
  }
}
.footer-links {
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
  .title {
    font-size: 13px;
    font-weight: bold;
    color: #171a1d;
    margin-bottom: 13px;
    display: flex;
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }
  .accordion-btn {
    margin-left: auto;
    cursor: pointer;
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }
  .data-list {
    margin: 0;
    padding: 0 0 15px;
    @media (max-width: 768px) {
      display: none;
    }
    li {
      list-style-type: none;
      margin-bottom: 15px;
      a {
        font-size: 13px;
        color: #5e6267;
        display: inline-block;
        &:hover {
          text-decoration: none;
          color: #3b1c5a;
        }
      }
    }
  }
  &.show {
    .data-list {
      @media (max-width: 768px) {
        display: block;
        padding: 0px;
        margin-top: 10px;
      }
    }
  }
}
.social-media {
  text-align: left;
  a {
    display: inline-block;
    padding: 0;
    margin: 0 8px;
    @media (max-width: 1200px) {
      margin: 0px;
    }
    &:last-child {
      margin: 0 0 0 8px;
      @media (max-width: 1200px) {
        margin: 0px;
      }
    }
    &:first-child {
      margin: 0 8px 0 0;
      @media (max-width: 1200px) {
        margin: 0px;
      }
    }
  }
}
