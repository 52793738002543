@import "../../theme/variables.scss";

.search-header {
  padding: 2em 2em 0 !important;
  background-color: $grey-light;
  border: 1px solid lightgray;
}

.ui.card {
  border-radius: 0 !important;
  width: 100% !important;
  box-shadow: 0 0 10px #cccccc !important;
  -moz-box-shadow: 0 0 10px #cccccc !important;
  -webkit-box-shadow: 0 0 10px #cccccc !important;
  -o-box-shadow: 0 0 10px #cccccc !important;
  .content {
    border: none !important;
    padding: 0 !important;
    font-size: 1.2rem;
    .ui.small.image {
      height: 100%;
      width: 25%;
      object-fit: cover;
      /* HOVER OFF */
      // -webkit-transition: padding 2s;
      // &:hover {
      //     padding: 10px;
      //    /* HOVER ON */
      //     -webkit-transition: border-radius 2s;
      //  }
    }
    .header {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      font-size: 1.7em !important;
      span {
        padding-right: 1rem;
        font-size: 0.8rem;
        color: $grey-middle;
      }
    }
    .meta-address {
      padding-top: 0 !important;
    }
  }
  .header {
    padding-top: 1.2rem;
  }
  .meta {
    padding-top: 0.8rem;
  }
  .ui.small.image {
    margin-bottom: 0 !important;
  }
  .right.aligned.content {
    margin-right: -4px;
  }
}

.right-arrow-icon {
  margin-top: -17px !important;
  cursor: pointer;
}

.ui {
  &.cards {
    text-align: left;
    &.property-search-card-scroll {
      max-height: 600px;
      overflow: auto;
      .property-card-container {
        max-height: 27%;
      }
    }
  }
}

.property-info-header .header {
  color: $grey-main !important;
  cursor: pointer;
  &:hover {
    color: $purple-splash-dark !important;
  }
}

.gm-style {
  .gm-style-iw-d {
    overflow: auto !important;
  }
  .gm-style-iw-c {
    padding: 0;
  }
  .ui.card > .image {
    width: 100%;
    max-width: 250px !important;
    img {
      width: 100%;
      max-height: 165px;
      object-fit: cover;
    }
  }
  .ui.card > .content {
    max-width: 250px;
  }
  .gm-ui-hover-effect {
    background: rgba(255, 255, 255, 1) !important;
    top: 0 !important;
    right: 0 !important;
  }
  .ui.card > .content > .header,
  .ui.cards > .card > .content > .header {
    margin: 0;
    padding: 13px 15px;
    text-align: left;
    font-size: 17px !important;
  }
}

// .modal {
//     display: none; /* Hidden by default */
//     position: fixed; /* Stay in place */
//     z-index: 1; /* Sit on top */
//     left: 0;
//     top: 0;
//     width: 100%; /* Full width */
//     height: 100%; /* Full height */
//     overflow: auto; /* Enable scroll if needed */
//     background-color: rgb(0,0,0); /* Fallback color */
//     background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
//     -webkit-animation-name: fadeIn; /* Fade in the background */
//     -webkit-animation-duration: 0.4s;
//     animation-name: fadeIn;
//     animation-duration: 0.4s
//   }
//   .semanticModal {
//     top: unset !important;
//     right: unset !important;
//     bottom: unset !important;
//     left: unset !important;
//     overflow-y: auto !important;
//     height:350px;
//   }

//   /* Modal Content */
//   .modal-content {
//       height:600px;
//     position: fixed;
//     bottom: 0;
//     background-color: #fefefe;
//     width: 100%;
//     -webkit-animation-name: slideIn;
//     -webkit-animation-duration: 0.4s;
//     animation-name: slideIn;
//     animation-duration: 0.4s
//   }

//   /* The Close Button */
//   .close {
//     color: white;
//     float: right;
//     font-size: 28px;
//     font-weight: bold;
//   }

//   .close:hover,
//   .close:focus {
//     color: #000;
//     text-decoration: none;
//     cursor: pointer;
//   }

//   .modal-header {
//     padding: 2px 16px;
//     background-color: RGB(58, 32, 97);
//     color: white;
//   }

//   .modal-body {padding: 2px 16px;}

//   .modal-footer {
//     padding: 2px 16px;
//     background-color: #5cb85c;
//     color: white;
//   }
//   iframe {
//       width:100%;
//       height:100%;
//   }
//   .modal-backdrop {
//       display:none;
//   }

//   /* Add Animation */
//   @-webkit-keyframes slideIn {
//     from {bottom: -300px; opacity: 0}
//     to {bottom: 0; opacity: 1}
//   }

//   @keyframes slideIn {
//     from {bottom: -300px; opacity: 0}
//     to {bottom: 0; opacity: 1}
//   }

//   @-webkit-keyframes fadeIn {
//     from {opacity: 0}
//     to {opacity: 1}
//   }

//   @keyframes fadeIn {
//     from {opacity: 0}
//     to {opacity: 1}
//   }

//   Booking modal css
.booking-modal {
  max-width: 530px;
  height: 526px;
  .content {
    height: 100%;
    .description {
      height: 100%;
    }
  }
  .modal-title {
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 5px;
  }
  .modal-subtitle {
    font-size: 13px;
    // line-height: 1.92;
    color: #5e6267;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .note-text {
    font-size: 13px;
    color: #00b574;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .form-control {
    height: 35px;
  }
  .field > label {
    position: static !important;
    color: #737373 !important;
    font-weight: 600 !important;
  }
}

// Booking.com details modal
.bookingDetailModal {
  z-index: 9999;
  .modal-content {
    max-width: 900px;
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: unset;
    height: 500px;
    .modal-header {
      padding: 15px 0;
      .close {
        padding: 0;
        top: 10px;
        font-weight: 500;
      }
    }
    .modal-body {
      padding: 0;
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
