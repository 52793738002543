.footer {
  &-wrapper {
    background-color: #f9f9f9 !important;
  }
  &-logo {
    padding-left: 50px !important;
  }
}

.footer-main {
  display: block;
  width: 100%;
  padding: 36px 50px;
  background-color: #f9f9fa;
}
.footer-inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #021e40;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-header {
  background: transparent;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  a,
  a:hover {
    color: #091e3e;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
}

.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
  background: #e8e8e8;
}

.inner-footer-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inner-footer-copyright {
  color: #091e3e;
}
.inner-footer-link {
  display: flex;
  justify-content: space-between;
  min-width: 220px;
  a,
  a:hover {
    font-size: 14px;
    color: #091e3e;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
}
