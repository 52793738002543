.color-red {
  color: red !important;
}
.booking-payment-wrap{
  margin-top: 95px;
  padding: 40px 0;
  min-height: calc(100vh - 95px);
  background-color: #f8f8f8;
  .cards{
    .card{
      padding: 20px !important;
      border-radius: 3px !important;
      box-shadow: 0 11px 14px -10px #e5eced !important;
    }
  }
  .payment-form-wrap{
    .column{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .form-title{
      font-size: 15px;
      font-weight: bold;
      color: #171a1d;
      margin-bottom: 20px;
    }
    .form-group{
      label{
        color: #5e6267;
      }
      .form-control{
        border-radius: 3px;
        background-color: #5e636808;
        box-shadow: none;
        &::-webkit-input-placeholder { /* Edge */
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
        
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
        
        &::placeholder {
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
      } 
    }
    .special-request-section{
      margin-bottom: 30px;
      .request-title{
        font-size: 13px;
        font-weight: 500;
        color: #171a1d;
        margin-top: 10px;
      }
      .request-subtitle{
        font-size: 11px;
        font-style: normal;
        line-height: 1.82;
        color: #5e6267;
        margin-top: 5px;
        margin-bottom: 15px;
      }
      .arrival-checklist{
        font-size: 13px;
        line-height: 1.54;
        color: #11ae7d;
        position: relative;
        display: flex;
        margin-top: 10px;
        &::before{
          content: '';
          width: 20px;
          min-width: 20px;
          height: 20px;
          background-image: url("/assets/img/green-check.svg");
          margin-right: 8px;
          margin-top: 2px;
        }
      }
    }

    .selected-room-info{
      .room-info-label{
        font-size: 14px;
        color: #5e6267;
        margin-bottom: 5px;
      }
      .room-info-title{
        font-size: 16px;
        font-weight: 600;
        color: #3b1c5a;
      }
    }
    .room-detail-list{
      .room-list-item{
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #5e63681a;
        &:last-child{
          border-bottom: 0;
          padding-bottom: 10px;
        }
        .additional-charges-row{
          width: 100%;
          > div{
            width: 100%;
          }
          .list-label{
            flex: 1 1 auto;
            .text-sm{
              font-size: 12px;
            }
          }
        }
        .list-label{
          font-size: 14px;
          color: #5e6267;
          img{
            margin-right: 6px;
          }
        }
        .list-value{
          font-size: 14px;
          font-weight: 500;
          color: #171a1d;
          &.total-price{
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
      .cancellation-label{
        font-size: 14px;
        color: #11ae7d;
      }
    }
    .room-fine-print{
      p{
        font-size: 12px;
        line-height: 1.67;
        color: #5e6267;
        margin-bottom: 12px !important;
      }
    }
    // Payment step 2 style
    .guest-info-row{
      margin: 0 -20px;
      display: flex;
      justify-content: space-between;
      .guest-info-col{
        padding: 0 20px;
        .guest-label{
          font-size: 12px;
          color: #5e6267;
        }
        .guest-title{
          font-size: 15px;
          font-weight: bold;
          color: #171a1d;
        }
        .guest-edit-btn{
          cursor: pointer;
        }
      }
    }
  }

  // Checkbox style
  .ui.checkbox label {
    font-size: 13px;
    line-height: normal;
    color: #171a1d;
    position: static;
    &:before {
      top: 4px !important;
      width: 14px!important;
      height: 14px!important;
      border-radius: 2px!important;
      border-color: #d4d4d5!important;
    }
  }
  .ui.checked.checkbox label {
    &:before {
      background-color: #3b1c5a!important;
      border-color: #3b1c5a!important;
    }
    &::after {
      top: 8px !important;
      content: ""!important;
      color: #fff!important;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      width: 8px!important;
      height: 4px!important;
      transform: rotate(-45deg);
      left: 3px!important;
    }
  }

  // Radio style
  // .ui.radio{
  //   label{
  //     width: 100%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     margin: 0;
  //     &::before{
  //       border-radius: 50% !important;
  //       top: 50%;
  //       transform: translateY(-50%);
  //       width: 12px !important;
  //       height: 12px !important;
  //       background: transparent;
  //       border: 2px solid #fff !important;
  //       box-shadow: 0 0 0 2px #5e6267 !important;
  //     }
  //     &::after{
  //       top: 50%;
  //       transform: translateY(-50%);
  //       left: 0;
  //       width: 12px !important;
  //       height: 12px !important;
  //     }
  //   }
  // }
  // .ui.checked.radio{
  //   label{
  //     &::before{
  //       background-color: #3b1c5a !important;
  //       border: 2px solid #fff !important;
  //       box-shadow: 0 0 0 2px #3b1c5a !important;
  //       width: 12px !important;
  //       height: 12px !important;
  //     }
  //     &::after{
  //       background-image: none;
  //       border: 0;
  //       width: 0 !important;
  //     }
  //   }
  // }

  .payment-method-row{
    margin-bottom: 30px;
    .custom-radio{
      width: 100%;
      margin: 0;
      .custom-control-label{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        position: static;
        padding-left: 12px;
        &::before{
          top: 6px;
          width: 12px;
          height: 12px;
          background: transparent;
          border: 2px solid #fff;
          box-shadow: 0 0 0 2px #5e6267;
          left: 0;
        }
        &::after{
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 12px;
          height: 12px;
        }
        .pay-title{
          font-size: 13px;
          font-weight: 600;
          color: #171a1d;
        }
        .pay-subtitle{
          font-size: 13px;
          color: #5e6267;
        }
      }
      .custom-control-input:checked ~ .custom-control-label{
        &::before{
          background-color: #3b1c5a;
          border: 2px solid #fff;
          box-shadow: 0 0 0 2px #3b1c5a;
          width: 12px;
          height: 12px;
        }
        &::after{
          background-image: none;
        }
      }
    }
  }

  .payment-btn{
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    .btn{
      padding: 0 20px;
      max-width: 450px;
      width: 100%;
    }
  }
  .form-switch-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-radius: 3px;
    background-color: #5e636808;
    margin-bottom: 20px;
    .switch-label{
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #171a1d;
    }
    .ui.fitted.toggle.checkbox{
      width: 17px;
      height: 12px;
      min-height: 12px;
      label{
        top: 0 !important;
        left: 0 !important;
        width: 17px;
        height: 12px;
        min-height: 12px;
        &::before{
          width: 17px;
          height: 11px;
          border: 2px solid #5e6267;
          background: none;
        }
        &::after{
          width: 5px;
          height: 5px;
          left: 3px;
          top: 3px;
          box-shadow: none;
          background: #5e6267;
        }
      }
      input{
        width: 17px;
        height: 12px;
      }
      input:checked~label{
        &::before{
          border: 2px solid #39378b;
          background-color: #39378b !important;
        }
        &::after{
          left: 10px;
          background-color: #fff;
        }
      }
    }
  }
}

.page-back{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .image{
    width: 35px;
    height: 35px;
    margin-right: 20px;
    padding: 5px;
    opacity: 0.5;
    background-color: #171a1d !important;
    border-radius: 50%;
    cursor: pointer;
  }
  .back-text{
    font-size: 13px;
    font-weight: 500;
    color: #171a1d;
    padding-bottom: 3px;
  }
}

// Step 3
.booking-completed-card{
  max-width: 650px;
  margin: 70px auto;
  padding: 30px 84px 50px;
  border-radius: 3px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #fff;
  .booking-img{
    background-image: url("/assets/img/booking-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 360px;
    width: 100%;
    padding: 75px 80px 0;
    margin: 0 auto;
    img{
      width: 200px;
      height: 200px;
      border-radius: 50%;
      padding: 50px;
      box-shadow: 0 10px 12px -8px #e5eced;
      background-color: #fff;
    }
  }
  .booking-card-title{
    font-size: 22px;
    font-weight: 800;
    color: #171a1d;
    margin-top: 27px;
    text-align: center;
  }
  .booking-card-subtitle{
    font-size: 15px;
    color: #5e6267;
    line-height: 1.73;
    margin-top: 5px;
    text-align: center;
  }
  .booked-room-row{
    display: flex;
    padding: 20px;
    border-radius: 3px;
    background-color: #5e636808;
    margin-top: 20px;
    .room-img{
      width: 70px;
      min-width: 70px;
      height: 70px;
      margin-right: 15px;
      opacity: 0.8;
      border-radius: 3px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
    }
    .room-title{
      font-size: 15px;
      font-weight: 600;
      color: #171a1d;
      &.primary{
        color: #3b1c5a;
      }
    }
    .room-subtitle{
      font-size: 12px;
      color: #5e6267;
      margin-top: 1px;
    }
  }
}

.button-wrapper{
  display: flex;
  margin: 0 -10px;
  .btn{
    flex: 1 1 auto;
    margin: 20px 10px 0;
    &.btn-cancel{
      padding: 0 14px 0 15px;
      border-radius: 3px;
      background-color: #5e6267;
    }
  }
}
