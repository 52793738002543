.faq-banner {
  background: url(/assets/img/marketing/faq-banner.jpg) no-repeat center;
  background-size: cover;
}

.faqs-section {
  margin: 100px 0;
  .nav-tabs {
    background: #f2f2f2;
    border: 0;
    @media (max-width: 768px) {
      background: unset;
    }
    &#myTab {
      @media (max-width: 768px) {
        display: none;
      }
    }

    .nav-item {
      flex: 1;
      margin: 5px;
    }
    .nav-link {
      font-size: 14px;
      padding: 25px 0px;
      text-align: center;
      font-weight: 500;
      color: #5e6267;
      border: 0;
      &.active {
        color: #3b1c5a;
        border-radius: 3px;
        border: 0;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .tab-content {
    margin: 40px 0 0;
    @media (max-width: 768px) {
      display: block;
    }
    .tab-pane {
      width: 100%;
      @media (max-width: 768px) {
        display: block;
        opacity: 1;
        margin-bottom: 20px;
      }

      .panel-heading {
        display: none;
        @media (max-width: 768px) {
          display: flex;
          background-color: #f2f2f2;
          padding: 0px 20px;
          cursor: pointer;
        }
      }
      .collapse {
        // display: block;
        @media (max-width: 768px) {
          display: none;
        }
        &.show {
          @media (max-width: 768px) {
            display: block;
          }
        }
      }
    }
  }
  .accordion {
    .card {
      background: #f2f2f2;
      border: 0;
      position: relative;
      margin: 0 0 20px;
      padding-bottom: 15px;
    }
    .card-header {
      border: 0;
      padding: 13px 30px 0;
      background-color: transparent;
      .btn {
        font-size: 14px;
        font-weight: 400;
        line-height: 2.43;
        color: #171a1d;
        padding: 0;
        display: flex;
        text-transform: none;
        text-decoration: none;
        font-family: "Be Vietnam", sans-serif !important;
        em {
          margin-left: auto;
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          display: flex;
          transform: rotate(180deg);
        }
        &.collapsed {
          color: #5e6267;
          em {
            transform: none;
          }
        }
      }
    }
    .card-body {
      padding: 5px 40px 10px 30px;
      font-size: 15px;
      line-height: 1.73;
      color: #5e6267;
    }
  }
}
