.top {
  &-renter {
    &-background {
      min-height: 500px !important;
      background-repeat: no-repeat !important;
      background-size: 100% !important;
      background-position: center !important;
      background-image: url("/assets/img/Renter/header.jpg") !important;
    }
    &-logo {
      padding-left: 50px !important;
    }
    &-header {
      color: white !important;
    }
    &-info {
      color: white !important;
      max-width: 50% !important;
      margin-left: 25% !important;
    }
  }
}
.middle {
  &-renter {
    &-empty {
      min-height: 650px !important;
    }

    &-background {
      min-height: 650px !important;
      background-image: url("/assets/img/Renter/clients-background.jpg") !important;
      background-repeat: no-repeat !important;
      background-size: 100% !important;
      background-position: center !important;
      &-two {
        min-height: 650px !important;
        background-image: url("/assets/img/Renter/customers-background.jpg") !important;
        background-repeat: no-repeat !important;
        background-size: 100% !important;
        background-position: center !important;
      }
    }
  }
}
