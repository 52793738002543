@import "../../theme/variables.scss";

.search-container {
  background-color: white;
  width: 100%;
  padding: 3rem 4rem;
  width: 900px !important;
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
  text-align: left;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12) !important;
  border-radius: 5px;
  font-size: 1.5rem;
}

.date-container {
  display: flex;
}

.ui.form input .property-search {
  background-color: white !important;
}

.inner-dropdown {
  overflow: inherit !important;
}

.menu-align-right {
  text-align: right !important;
}

// .react-datepicker-wrapper{
//     // width: 30%;
//     .react-datepicker__input-container input{
//         border: none !important;
//         background-color: #F2F3F5 !important;
//         line-height: 30px;
//         color: #96A0B4!important;
//         transition: 0.4s;
//         border-radius: 0px!important;
//     }
// }

.ui {
  &.form {
    .field {
      &.property-search-duration-field {
        padding-left: 1em !important;
        .ui {
          &.selection {
            &.active {
              &.drowdown {
                &:hover {
                  box-shadow: none;
                }
              }
            }
            &.dropdown {
              background-color: transparent !important;
              color: #4d158a;
              padding-left: 0;
              padding-top: 5px;
              box-shadow: none;
              &:focus {
                border: 0 !important;
              }
              &:not(.button) > .default.text {
                color: #4d158a;
              }
              .dropdown {
                &.icon {
                  display: none;
                }
              }
            }
          }
        }
      }
      &.field {
        &.property-search-duration-field {
          input,
          input:focus {
            background-color: transparent !important;
            color: #4d158a;
            padding-left: 0;
            padding-top: 5px;
            outline: 0;
            border: 0 !important;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.ui.form {
  .field.field.location-search-field {
    input {
      padding-left: 43px;
      &:focus {
        border: 0 !important;
      }
    }
    .search {
      &.icon {
        position: absolute;
        top: 20px;
        left: 12px;
      }
    }
  }
  .ui.primary.button.main-button {
    height: 50px;
    align-self: flex-end;
    margin-bottom: 2px;
    line-height: 15px;
  }
}

.ui.form .fields.min-max-input {
  margin-top: 13px;
  margin-left: 1px;
  margin-right: 1px;
}

.ui.pointing.dropdown > .menu.beds-inner-dropdown {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-top: 0;
  &::after {
    display: none;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.search-container .ui.form .field.field input,
.search-container .ui.fluid.dropdown .ui.selection.dropdown {
  border: 1px solid transparent !important;
}
.search-container .ui.form .field.field input:focus,
.search-container .ui.fluid.dropdown .ui.selection.dropdown:focus {
  border: 1px solid #c9f2ee !important;
  .default.text {
    color: #4d158a;
  }
}

.search-container .ui.form .field.field.location-search-field input {
  padding-left: 20px;
}

.dropdown-col {
  height: 38px;
  width: 150px !important;
  margin: 0 4px !important;
  position: relative !important;
  z-index: 1;
  &:hover {
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
  }
  &-menu {
    width: 180px !important;
  }
  &-min {
    margin-left: 18px !important;
    min-width: 80px !important;
  }
  &-max {
    min-width: 80px !important;
  }
}

.filters {
  &.additional {
    &-label {
      background-color: white !important;
      padding: 0 !important;
      margin-left: 6px !important;
    }
    &-check {
      position: absolute;
      bottom: 14px;
    }
  }
}

.inner-menu {
  position: absolute !important;
  left: 5px !important;
  overflow: visible !important;
  z-index: 9999;
}

// Search form Listing style
.search-more-row {
  .search-more-label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5e6267;
    margin: 0 5px 10px;
  }
}
.longsearch-rental-wrap {
  display: flex;
  .search-with-icon {
    margin: 0 5px;
    .inner-dropdown {
      .dropdown {
        padding-left: 10px !important;
        .menu {
          left: 5px !important;
        }
      }
    }
  }
}
.search-rental-wrap {
  display: flex;
  .short-rental-placeholder {
    display: flex;
    align-items: center;
    span {
      padding-right: 10px;
      display: flex;
      align-items: center;
      &::after {
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #b9b9b9;
        margin-left: 8px;
        margin-top: 3px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  .short-rental-dropdown {
    width: 300px !important;
    .ui.dropdown {
      width: 100% !important;
      transform: none;
    }
  }
  .short-rental-row {
    padding: 5px 0 !important;
    max-height: max-content !important;
    .short-rental-column {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      .short-rental-label {
        font-size: 14px;
        color: #5e6267;
        .text-sm {
          font-size: 11px;
        }
      }
      .short-rental-buttons {
        display: flex;
        align-items: center;
        margin: 0 -10px;
        .rental-btn-count {
          font-size: 16px;
          width: 20px;
          text-align: center;
        }
        .button {
          padding: 3px;
          background: none;
          border: 1px solid #ccc;
          border-radius: 0;
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
    .rental-age-wrap {
      margin: 10px -5px;
      display: flex;
      flex-wrap: wrap;
      padding: 0 10px;
      .rental-age-col {
        padding: 0 5px 10px;
        width: 50%;
        .dropdown {
          padding-left: 10px !important;
          .menu {
            height: auto !important;
            left: 5px !important;
            top: 100% !important;
            overflow: auto;
          }
          &.visible {
            .menu {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// Amenities dropdown 
.amenities-item{
  .amenities-checkbox{
    position: relative;
    width: 100%;
    input{
      height: 14px !important;
      top: 5px !important;
      left: 6px !important;
      &:checked~label{
        &::before{
          background-color: #3b1c5a!important;
          border-color: #3b1c5a!important;
        }
        &::after{
          content: '' !important;
          border-left: 1px solid #fff;
          border-bottom: 1px solid #fff;
          width: 8px!important;
          height: 4px!important;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
    label{
      top: 0 !important;
      left: 8px !important;
      padding-bottom: 4px;
      &::before{
        top: 5px !important;
        width: 14px!important;
        height: 14px!important;
        border-radius: 2px!important;
        border-color: #d4d4d5!important;
      }
      &::after{
        top: 8px !important;
        left: 3px !important
      }
    }
  }
}
